/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"fotoestartit"}>
        <Column className="--menu pb--08 pt--08" style={{"backgroundColor":"rgba(255,255,255,1)"}} menu={true}>
          
          <Menu className="--right" style={{"maxWidth":1310}} fullscreen={false}>
            
            <ColumnWrapper className="menu-logo-box --center" style={{"maxWidth":310}}>
              
              <Image style={{"maxWidth":142}} alt={""} src={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png"} svg={false} href={"/"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=350x_.png 350w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=660x_.png 660w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png 860w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=2000x_.png 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--style4 --right">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kurzy"} target={""} content={"Kurzy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/zajezdy"} target={""} content={"Zájezdy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/obchod"} target={""} content={"Obchod"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/sluzby"} target={""} content={"Služby"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/bazen"} target={""} content={"Bazén"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/klub"} target={""} content={"Klub"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/o-nas"} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/fotogalerie"} target={""} content={"Fotogalerie"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kontakt"} target={""} content={"Kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column name={"uo5q93xqcm"} style={{"paddingTop":10,"paddingBottom":18.45001220703125}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box ff--3" content={"Španělsko, Estartit<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"wea4kqbpfmj"} style={{"paddingTop":20}} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/2276b80462434c88b2f6c4ac4f981f56_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/2276b80462434c88b2f6c4ac4f981f56_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/2276b80462434c88b2f6c4ac4f981f56_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/2276b80462434c88b2f6c4ac4f981f56_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/2276b80462434c88b2f6c4ac4f981f56_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/baf41e98c2c84ead82ab33bb404e79ed_s=350x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/baf41e98c2c84ead82ab33bb404e79ed_s=350x_.jpg 350w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/0535607b40674461add19c4ae9da6970_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/0535607b40674461add19c4ae9da6970_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/0535607b40674461add19c4ae9da6970_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/0535607b40674461add19c4ae9da6970_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/1f216d0672f94843b5f50a1041292394_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/1f216d0672f94843b5f50a1041292394_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/1f216d0672f94843b5f50a1041292394_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/1f216d0672f94843b5f50a1041292394_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":20.083343505859375,"paddingTop":0}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/1f9ce86dc9094d1f8a392f43052734e3_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/1f9ce86dc9094d1f8a392f43052734e3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/1f9ce86dc9094d1f8a392f43052734e3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/1f9ce86dc9094d1f8a392f43052734e3_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/f3a04d5a22e3453095b9663aaa19fbfa_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/f3a04d5a22e3453095b9663aaa19fbfa_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/f3a04d5a22e3453095b9663aaa19fbfa_s=660x_.jpg 660w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/21c14907a9a440ffa4f183c5f7458c4f_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/21c14907a9a440ffa4f183c5f7458c4f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/21c14907a9a440ffa4f183c5f7458c4f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/21c14907a9a440ffa4f183c5f7458c4f_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/de5a72981afc42e98f7ba22cd4e04cbd_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/de5a72981afc42e98f7ba22cd4e04cbd_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/de5a72981afc42e98f7ba22cd4e04cbd_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/de5a72981afc42e98f7ba22cd4e04cbd_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}